import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_group_form = _resolveComponent("group-form")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, {
    title: "Create Group",
    "back-link": "true"
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_ion_loading, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_group_form, { onGroupSubmit: $options.create }, null, 8, ["onGroupSubmit"])
    ]),
    _: 1
  }))
}