import {Group} from '@/model/group.model';
import {GroupType} from "@/model/enumerations/group-type.model";

export default {

     reset: function(group: Group) {
        group.name = '';
        group.description = '';
        group.privateGroup = false;
        group.city = '';
        group.state = 'FL';
        group.type = GroupType.MULTISPORT;
    }
}



