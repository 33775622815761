
import {add} from 'ionicons/icons';
import BaseLayout from "@/components/BaseLayout.vue";
import GroupForm from "@/components/form/groupForm.vue";
import { IonLoading} from "@ionic/vue";
import GroupHelper from "@/helpers/GroupHelper";

export default  {
  name: 'CreateGroup',
  components: {
    GroupForm,
    BaseLayout,
    IonLoading
  },
  setup() {
    return {
      add,
    }
  },
  computed:{
  },
  methods:{
    create(group) {
      this.loading = true;
      this.$store.dispatch('group/createGroup', group).then(
          () => {
            this.loading = false;
            GroupHelper.reset(group);
            this.$router.replace("/groups");
            // this.$router.push('/groups');
          }
      ).catch((error) => {
        this.loading = false;
        this.presentAlert(error.response.data.title);
      });

    },
    data(){
      return{
        loading: false,
      }
    }
  },
}
